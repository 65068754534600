import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import { setCurrentPage } from '../../Store/Auth';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function Pricing() {

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("pricing"));
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pt-4" style={{ width: "80%", margin: "0px auto" }}>
                <h1 className="text-center">Pricing</h1>
                <div className="mobile60width" style={{ fontSize: "16pt", textAlign: "center", margin: "20px auto 50px auto"}}>
                    Built for growing teams, our platform is ready to help you get started. 
                    Discover the solution that fits your needs. 
                </div>
                <div className="row mb-4 pb-4">
                    <div className="col-md-4 text-center" style={{ 
                        height: "600px", border: "2px solid #3498db", 
                        borderRadius: "2px", padding: "50px" }}>
                        <div style={{ fontSize: "14pt", fontWeight: "bold" }}>FREE</div>
                        <div>
                            <div style={{ fontWeight: "bold", height: "60px" }}>
                                $<span style={{ fontSize: "30pt" }}>0</span>.00 USD
                            </div>
                            <div style={{ fontSize: "10pt", color: "gray", height: "60px"}}>
                                Free for all users and teams
                            </div>
                        </div>
                        <div style={{ height: "80px" }}>
                            For individuals or teams looking to organize any project.
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <a href="" className="btn-orange-outline">
                                Get Started
                            </a>
                        </div>
                        <hr style={{ color: "gray", marginTop: "50px" }}></hr>
                        <ul style={{ textAlign: "left"}}>
                            <li>Up to 200 cards</li>
                            <li>Up to 2 Containers</li>
                            <li>Up to 5 Boards per Container</li>
                            <li>AI-powered work management</li>
                        </ul>
                    </div>
                    <div className="col-md-4 text-center" style={{ 
                        height: "600px", border: "1px solid #f2f3f4", 
                        padding: "50px", position: "relative" }}>
                        <div style={{ fontSize: "14pt", fontWeight: "bold" }}>PRO</div>
                        <div>
                            <div style={{ fontWeight: "bold", height: "60px" }}>
                                $<span style={{ fontSize: "30pt" }}>9</span>.99 USD
                            </div>
                            <div style={{ fontSize: "10pt", color: "gray", height: "60px"}}>
                                Per user/month if billed annually ($10.99 billed monthly)
                            </div>
                        </div>
                        <div style={{ height: "80px" }}>
                            For small teams that need to manage work and scale collaboration.
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <a href="" className="btn-orange-outline">
                                Sign up Now
                            </a>
                        </div>
                        <hr style={{ color: "gray", marginTop: "50px" }}></hr>
                        <ul style={{ textAlign: "left"}}>
                            <li>Unlimited cards</li>
                            <li>Up to 10 Containers</li>
                            <li>Up to 20 Boards per Container</li>
                            <li>AI-powered work management</li>
                        </ul>
                        <div style={{ position: "absolute", bottom: "0", left: "0", paddingBottom: "10px", textAlign: "center",
                            width: "100%", margin: "0px auto"
                         }}>
                            <a href="">Learn more about Pro</a>
                        </div>
                    </div>
                    {/*
                    <div className="col-md-3 text-center" style={{ 
                        height: "500px", border: "1px solid #f2f3f4", 
                        paddingTop: "50px", position: "relative" }}>
                        <h3>Pro</h3>
                        <div>Yeah, we get to charge you even more money</div>
                        <div style={{ position: "absolute", bottom: "0", paddingBottom: "10px", textAlign: "center",
                            width: "100%", margin: "0px auto"
                         }}>
                            <a href="">Learn more about Pro</a>
                        </div>
                    </div>
                    */}
                    <div className="col-md-4 text-center" style={{ 
                        height: "600px", border: "1px solid #f2f3f4", 
                        padding: "50px", position: "relative" }}>
                        <div style={{ fontSize: "14pt", fontWeight: "bold" }}>ENTERPRISE</div>
                        <div>
                            <div style={{ fontWeight: "bold", height: "60px" }}>
                                <span style={{ fontSize: "30pt" }}></span>Custom
                            </div>
                            <div style={{ fontSize: "10pt", color: "gray", height: "60px" }}>
                                Speak with sales to get your custom pricing
                            </div>
                        </div>
                        <div style={{ height: "80px" }}>
                            For organizations that need to connect work across teams with more security and controls.
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <a href="" className="btn-orange-outline">
                                Contact Sales
                            </a>
                        </div>
                        <hr style={{ color: "gray", marginTop: "50px" }}></hr>
                        <ul style={{ textAlign: "left"}}>
                            <li>Unlimited cards</li>
                            <li>Unlimited Containers</li>
                            <li>Unlimited Boards per Container</li>
                            <li>AI-powered work management</li>
                        </ul>
                        <div style={{ position: "absolute", bottom: "0", left: "0", paddingBottom: "10px", textAlign: "center",
                            width: "100%", margin: "0px auto"
                         }}>
                            <a href="">Learn more about Enterprise</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Pricing;